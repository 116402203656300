<script setup>
import { computed } from 'vue';
const model = defineModel()
const props = defineProps(["onSubmit", "selectDelivery", "setStatus"])
let total_price = computed(() => {
    let total_price = 0
    for (let product of model.value){
        total_price += product.quantity * product.price
    }
    return total_price
})
let deleteProduct = (product) => {
    fetch("/api/cart/delete?id=" + product.id).then(resp => resp.json()).then(json=>{
        props.setStatus(json.status, json.reason)
        if(json.status == "success"){
            model.value = model.value.filter(p => product.id != p.id)
        }
    })
}
let decreaseInCart = (product) => {
    fetch("/api/cart/decrease?id=" + product.id).then(resp => resp.json()).then(json=>{
        if(json.status == "failed"){
            props.setStatus("failed", json.reason)
        }else{
            model.value = model.value.filter(iter_product => {
                if(iter_product.id == product.id){
                    iter_product.quantity -= 1
                    if(iter_product.quantity == 0)
                        return false;
                }
                return true
            }),
            props.setStatus("success", json.reason)
        }
    })
}
let addToCart = (product) =>{
    fetch("/api/cart/add?id=" + product.id).then(resp => resp.json()).then(json=>{
        if(json.status == "failed"){
            props.setStatus("failed", json.reason)
        }else{
            if(model.value.filter(iter_product => {
                if(iter_product.id == product.id){
                    iter_product.quantity += 1
                    return true
                }else{
                    return false
                }
            }).length == 0){
                this.model.value = [...model.value, {...product, quantity: 1}]
            }
            props.setStatus("success", json.reason)
        }
    })
}
</script>
<template>
    <div class="container">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Název</th>
                    <th scope="col">Množství</th>
                    <th scope="col">Cena</th>
                    <th scope="col">Smazat</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="product in model" :key="product.id">
                    <td>{{product.name}}</td>
                    <td>
                        <button type="button" v-if="!product.locked" class="btn btn-light" @click="addToCart(product)">+</button>
                        {{product.quantity}}
                        <button type="button" v-if="!product.locked" class="btn btn-light" @click="decreaseInCart(product)">-</button>
                    </td>
                    <td>{{product.price}} Kč</td>
                    <td><button type="button" v-if="!product.locked" class="btn btn-light" @click="deleteProduct(product)">Smazat</button></td>
                </tr>
                <tr>
                    <td>Celkem</td>
                    <td><button class="btn btn-primary" type="submit" @click="props.selectDelivery()">Vybrat Zásilkovnu</button></td>
                    <td>{{total_price}} Kč</td>
                    <td><form id="form" action='/api/cart/buy' @submit.prevent="props.onSubmit(undefined)"><button class="btn btn-primary" type="submit"><input type="hidden" name="something" value="nothing" />Koupit</button></form></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>