<script setup>
const props = defineProps(['products', 'addToCart'])
</script>
<template>
    <div class="container" :style='{display: "flex"}'>
        <div v-for="product in props.products" class="card" :style='{width: "18rem"}'>
            <img :src="product.img" class="card-img-top" alt="..." />
            <div class="card-body">
                <h5 class="card-title">{{product.name}}</h5>
                <p class="card-text">{{product.description}}</p>
                {{product.price}} Kč <a href="#" @click.prevent="props.addToCart(product)" class="btn btn-primary">Přidat do košíku</a>
            </div>
        </div>
    </div>
</template>