<script setup>
import {computed, onMounted, onUnmounted, ref} from "vue";
import moment from 'moment';
import {sprintf} from 'sprintf-js';


let live_results = ref([])
let stations = ref([])
let ws = ref(undefined)

let starts = computed(() => {
    let starts = {}
    for (const result of live_results.value.filter(x => x.station_order == 1)){
        starts[result.starting_number] = moment(result.time)
    }
    return starts;
})
let results = computed(() => {
    let results = []
    for (const station of stations.value){
        let live_result = {
            order: station.order,
            station: station.name,
            results: [],
        }
        for(const result of live_results.value.filter(x => x.station_order == station.order)){
            let duration_from_start = moment.duration(moment(result.time).diff(starts.value[result.starting_number]))
            let hours = duration_from_start.asHours()
            let minutes = duration_from_start.minutes()
            let seconds = duration_from_start.seconds()
            let duration = sprintf("%d:%02d:%02d", hours, minutes, seconds)

            live_result.results.push({
                starting_number: result.starting_number,
                time_milis: new Date(result.time) - new Date(starts.value[result.starting_number]),
                time: result.station_order == 1 ? moment(result.time) : duration,
            })
        }
        if (station.order != 1){
            live_result.results.sort((x, y) => x.time_milis - y.time_milis)
            for(var i = 0; i < live_result.results.length; i++){
                live_result.results[i].order = i + 1
            }
        }else{
            live_result.results.sort((x, y) => x.time.diff(y.time))
            live_result.results = live_result.results.map(x => ({...x, time: x.time.format("H:mm:ss")}))
        }
        results.push(live_result)
    }
    return results
})

function initWS(){
    // Connect to server
    let ws = new WebSocket("wss://" + window.location.host + "/api/live/results")
    ws.onmessage = (event) => {
        let data = JSON.parse(event.data)
        live_results.value = [...live_results.value, ...data]
    }
    ws.onerror = () => {
        window.open("/#" ,"_self")
    }
    return ws
}
onMounted(()=>{
    fetch("/api/stations").then(resp => resp.json()).then(json => {
        stations.value = json
    })
    ws.value = initWS();
})
onUnmounted(() => {
    ws.value.close()
    ws.value = undefined
})
</script>
<template>
    <div class="container">
        <h1 class="text">Výsledky živě</h1>
        <div v-for="live_result in results">
            <h2 class="text">{{live_result.order}}. {{live_result.station}}</h2>
            <table class="table">
                <thead>
                    <tr>
                        <th v-if="live_result.order != 1" scope="col">Pořadí</th>
                        <th scope="col">Startovní číslo</th>
                        <th scope="col">Team</th>
                        <th scope="col">{{live_result.order == 1 ? "Startovní čas" : "Čas"}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="res in live_result.results" :key="res.starting_number">
                        <td v-if="res.order">{{res.order}}.</td>
                        <td>{{res.starting_number}}</td>
                        <td>{{res.team}}</td>
                        <td>{{res.time}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>