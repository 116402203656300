<script setup>
let props = defineProps(['onSubmit'])
</script>
<template>
    <form id="form" action="/api/forgotten_password" class="container" method="post" @submit="props.onSubmit">
        <div class="mb-3">
            <label for="email" class="form-label">Přihlašovací email:</label>
            <input name="email" class="form-control" type="email" />
        </div>
        <div class="mb-3">
            <label for="code" class="form-label">Kód pro obnovení hesla:</label>
            <input type="text" class="form-control" name="code"/>
        </div>
        <div class="mb-3">
            <label for="password1" class="form-label">Nové heslo:</label>
            <input type="password" class="form-control" name="password1"/>
        </div>
        <div class="mb-3">
            <label for="password2" class="form-label">Nové heslo znova:</label>
            <input type="password" class="form-control" name="password2"/>
        </div>
        <button type="submit" class="btn btn-primary">Obnovit heslo a přihlásit</button>
    </form>
</template>