<script setup>
const props = defineProps(["racers", "onEditRacer"])
</script>
<template>
    <div class="container">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Jméno</th>
                    <th scope="col">Přijmení</th>
                    <th scope="col">Email</th>
                    <th scope="col">Team</th>
                    <th scope="col">Datum narození</th>
                    <th scope="col">Telefon</th>
                    <th scope="col">Zaplatil</th>
                    <th scope="col">Změnit</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="racer in props.racers">
                    <td>{{racer.first_name}}</td>
                    <td>{{racer.last_name}}</td>
                    <td>{{racer.email}}</td>
                    <td>{{racer.team}}</td>
                    <td>{{racer.date_of_birth}}</td>
                    <td>{{racer.phone}}</td>
                    <td>{{racer.paid?"Zaplatil":"Nezaplatil"}}</td>
                    <td><button type="button" class="btn btn-light" @click="props.onEditRacer(racer)">Změnit</button></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>