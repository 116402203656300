<script setup>
const props = defineProps(['togglefaq'])
</script>
<template>
    <div class="faq" @click="props.togglefaq()">
        <div class="container" @click="(e) => e.stopPropagation()">
            <button type="button" class="btn-close" aria-label="Close" @click="props.togglefaq()"></button>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Proč je cena startovného taková, jaká je?</h5>
                    <p class="card-text">Vetšinu ceny dělá alkohol a nápoje, které se během závodu konzumují. Dostanete přívěšek s panákem, který Vám zůstává a pokud závod doběhnete, dostanete památeční medaili. Zbytek startovného tvoří výherní ceny, dále náklady spojené s organizací závodu. Výroba překážek, časovačů na čekárnách, náramky pro závodníký, tisk plakátů a jejich propagace, atd. Cenu jsme se snažili nastavit co nejnižší, zároveň takovou, abychom byli schopní Alkátor Race uskutečnit.</p>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Musím v Alkátor race utíkat nebo se jinak fyzicky namáhat?</h5>
                    <p class="card-text">Strategii závodu si určím sám s pomocí mého osobního trenéra. Tudíž se zaměřím hlavně na alkohol a abych dorazil do cíle jako hrdina a ostřílený alko-veterán.</p>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Co když jsem zde svůj dotaz nenašel?</h5>
                    <p class="card-text">Tak neváhej a kontaktuj nás na sociálních sítích nebo emailu, rádi pomůžeme.</p>
                </div>
            </div>
        </div>
    </div>
</template>