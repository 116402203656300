<script setup>
</script>
<template>
    <div class="container">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Token</h5>
                <p class="card-text">V kontextu Alkátor Race by se to dalo spíše nazvat kontrolním bodem. Závodník doběhne na určité místo, kde token sebere a následně ho zanese na další stanoviště, kde ho předáte kontrolorovi. Tím dáte najevo, že jste běžel trasu správě. Je to pojistka, aby jsi byl poctivý Alkátor a nešvindloval!</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Quiz</h5>
                <p class="card-text">dná se o překážku, kde na daném místě vylosujete otázku a běžíte k dalšímu stanovišti ke kontrolorovi. Mezi tím si můžete promyslet jednu z ze tří odpovědí (formát A,B,C). Špatná odpověď znamená panáčka. Navíc to má obdobnou funkci, jako Token.</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Lentilky</h5>
                <p class="card-text">Na stanovišti na Vás budou čekat panáčky. Obsah může být cokoliv alkoholického i nealkoholického, od dobrot po blééé. Nejlepší na tom je, že podle čichu a barvy nemáte šanci poznat, o co se jedná. Jelikož panáčky jsou uzavřené a dobarvené potravinářským barvivem. Pohled na ně připomínají lentilky.</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Čekárna</h5>
                <p class="card-text">Jedná se o stanoviště, kde se usadíte a dostanete pivečko. Při doběhnutí na čekárnu si spustíte časovač a je třeba tento čas na čekárně strávit. Většinou je délka čekání 10 minut. Zde si můžete popovídat s ostatními závodníky a sdílet si své zážitky. Zároveň stihne působit doposud vypitý alkohol.</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Dájovy pochcaný trenky</h5>
                <p class="card-text">Legenda praví, že jistý organizátor Dája si pochcal své trenky a nemohl se trefit do koše se špinavým prádlem. Princip překážky je jednoduchý, máte dvoje trenky, tudíž dva pokusy. Trenky jsou namočené v kýblu vody a musíte alespoň jedny trenky hodit do druhého kýblu. Pokud se trefíte, běžíte bez postihu dále. Pokud ne, čeká Vás panáček. Může se stát, že trenky přistanou na hranu kýble, v tomto případě platí pokus, když se trenky nedotýkají země</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Alchymie</h5>
                <p class="card-text">Umíchej si vlastní drink! Doběhnete ke překážce, kde na Vás čekají tří kola neštěstí. S každými koly zatočíte a to, co Vám padne, to si vypijete. Kola obsahují ingredience: alkohol, nealko a koření.</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Najdi láhev</h5>
                <p class="card-text">Ve vyhrazeném prostoru jsou schované lahve s alkoholem. Od měkčích až po tvrďáky. Podle toho, co si najdeš, to si vypiješ! Lahve jsou podle procent alkoholu schované. Tvrdší jsou viditelné lépe, měkčí jsou lépe schované. Je na Vás, pokud chcete trávit čas hledáním, nebo zvolíte bolestivější, ale rychlejší cestu.</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Najdi a přines</h5>
                <p class="card-text">Ve vyhrazeném prostoru dostanete za úkol najít nějaký předmět a následně jej donést zpět. Brnkačka.</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Šuk a lípy</h5>
                <p class="card-text">Bohužel se nejedná o sex u lípy, nýbrž o vtipné pojmenování překážky Luk a šípy. Dostanete luk a šíp(y) a musíte trefit terč. Pokud ho netrefíte, pijete panáčka.</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Černý Alkátor</h5>
                <p class="card-text">Na místě budou na Vás čekat několik karet a musíte si jednu vylosovat. Pokud si vytáhnete Černého Petr… chci říct, Alkátora, tak pijete panáčka. V opačném případě běžíte bez postihu dále.</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Stařena</h5>
                <p class="card-text">Bude na Vás čekat stařena, která Vám dá hádanku. Pokud hádanku uhádnete, běžíte dále. V opačném případě dostanete panáčka.</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Švihadla</h5>
                <p class="card-text">Prostě skáčete určitý počet skoků přes švihadlo.</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Pneu</h5>
                <p class="card-text">Stejně, jako v amerických filmech, kde vojáci na cvičišti přecházejí přes pneumatiky. Vaším úkolem je dokázat, že Alkátor je stejně, ne-li lépe schopný přejít dráhu z pneumatik.</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Přehazování pneu</h5>
                <p class="card-text">Vaším úkolem bude přeházet pneumatiky z jednoho místa na druhé.</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Převracení pneu</h5>
                <p class="card-text">Alkátor je stejně silný, jako kulturista. Vaším úkolem bude převrátit velkou pneumatiku.</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Skládačky</h5>
                <p class="card-text">Jedná se o překážku s logickou překážku. Buď něco postavit, nebo dokončit jinou logickou hádanku. Za nesplnění hrozí panáček nebo i fyzický trest (např. dřepy).</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Barvy (Barely)</h5>
                <p class="card-text">Už jste někdy nesli velký kýbl barvy? Pokud ne, tak tady si to můžete vyzkoušet. Princip je přenést těžkou nádobu na určité místo.</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Skok v pytli</h5>
                <p class="card-text">Již název napovídá, Budete mít za úkol skákat v pytli určitou vzdálenost.</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Opilecká stezka</h5>
                <p class="card-text">Každý, kdo jde z hospody, tak musí trefit domů. Bude na Vás čekat nějaká stezka, kterou za určitých podmínek musíte překročit. Pokud se Vám to nepodaří, pijete panáčka.</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Individuální překážky</h5>
                <p class="card-text">Někdy nám samotná lokace závodu naskytne příležitost využít možnosti nějaké speciální překážky. Jejich pravidla se dozvíte až na místě.</p>
            </div>
        </div>
    </div>
</template>
