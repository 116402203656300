<script setup>
let props = defineProps(['onSubmit'])
</script>
<template>
    <form id="form" class="container" action="/api/register_racer" @submit="props.onSubmit">
        <h1>Registrace Závodníka</h1>
        <div class="mb-3 form-check">
            <select class="form-select form-select-lg mb-3" name="alkator_category" aria-label="Large select example">
                <option value="1" selected>Alkátor (plný zážitek Alkátor Race)</option>
                <option value="2">Alkátor light (panáky jsou volitelné)</option>
                <option value="3">Nealkátor (300 kč, bez alkoholu)</option>
                <option value="4">Pivátor (349 kč, pouze piva a běh bez překážek)</option>
            </select>
        </div>
        <div class="mb-3">
            <label for="first_name" class="form-label">Jméno</label>
            <input type="text" class="form-control" id="first_name" name="first_name" />
        </div>
        <div class="mb-3">
            <label for="last_name" class="form-label">Přijmení</label>
            <input type="text" class="form-control" id="last_name" name="last_name" />
        </div>
        <div class="mb-3">
            <label for="email" class="form-label">Emailová adresa (volitelné)</label>
            <input type="email" class="form-control" id="email" name="email" />
        </div>
        <div class="mb-3">
            <label for="date_of_birth" class="form-label">Datum narození</label>
            <input type="date" class="form-control" id="date_of_birth" name="date_of_birth" />
        </div>
        <div class="mb-3">
            <label for="phone" class="form-label">Telefonní číslo (volitelné)</label>
            <input type="text" class="form-control" id="phone" name="phone" />
        </div>
        <div class="mb-3">
            <label for="phone" class="form-label">Team (volitelné)</label>
            <input type="text" class="form-control" id="team" name="team" />
        </div>
        <div class="mb-3 form-check">
            <input type="checkbox" class="form-check-input" id="agreement" name="agreement" />
            <label class="form-check-label" for="agreement">Souhlas se <a href="/public/ochrana_osobnich_udaju.pdf">zpracováním osobních údajů</a>, <a href="/public/reverz.pdf">podmínkami závodu</a> a <a href="/public/obchodni_podminky_alkator.pdf">obchodníma podmínkami závodu</a>.</label>
        </div>
        <button type="submit" class="btn btn-primary">Registrovat a zaplatit</button>
    </form>
</template>