<script setup>
const props = defineProps(['togglefaq'])
</script>
<template>
    <div class="faq" @click="props.togglefaq()">
        <div class="container" @click="(e) => e.stopPropagation()">
            <button type="button" class="btn-close" aria-label="Close" @click="props.togglefaq()"></button>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Proč je cena startovného taková, jaká je?</h5>
                    <p class="card-text">Vetšinu ceny dělá alkohol a nápoje, které se během závodu konzumují. Dostanete přívěšek s panákem, který Vám zůstává a pokud závod doběhnete, dostanete památeční medaili. Zbytek startovného tvoří výherní ceny, dále náklady spojené s organizací závodu. Výroba překážek, časovačů na čekárnách, náramky pro závodníký, tisk plakátů a jejich propagace, atd. Cenu jsme se snažili nastavit co nejnižší, zároveň takovou, abychom byli schopní Alkátor Race uskutečnit.</p>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">K čemu slouží Tokken?</h5>
                    <p class="card-text">V kontextu Alkátor Race by se to dalo spíše nazvat kontrolním bodem. Závodník doběhne na určité místo, kde tokken sebere a následně ho zanese na další stanoviště, kde ho předáte kontrolorovi. Tím dáte najevo, že jste běžel trasu správě. Je to pojistka, aby jsi byl podctivý Alkátor a nešvindloval!</p>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Jak funguje Quíz?</h5>
                    <p class="card-text">Jedná se o překážku, kde na daném místě vylosujete otázku a běžíte k dalšímu stanovišti ke kontrolorovi. Mezi tím si můžete promyslet jednu z ze tří odpovědí (formát A,B,C). Špatná odpopvěď znamená panáčka. Navíc to má obdobnou funkci, jako Tokken.</p>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Jak funguje Ruleta?</h5>
                    <p class="card-text">Na stanovišti na Vás budou čekat panáčky. Obsah může být cokoliv alkoholického i nealkoholického, od dobrot po blééé. Nejlepší na tom je, že podle čichu a barvy nemáte šanci poznat, o co se jedná. Jelikož panáčky jsou uzavřené a dobarvené potravinářským barvivem.</p>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Musím v Alkátor race utíkat nebo se jinak fyzicky namáhat?</h5>
                    <p class="card-text">Strategii závodu si určím sám s pomocí mého osobního trenéra. Tudíž se zaměřím hlavně na alkohol a abych dorazil do cíle jako hrdina a ostřílený alko-veterán.</p>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Kolik spoluúčastníků mám očekávat?</h5>
                    <p class="card-text">Zatím je kapacita závodu 50 účastníků.</p>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Co když jsem zde svůj dotaz nenašel?</h5>
                    <p class="card-text">Tak neváhej a kontaktuj nás na sociálních sítích nebo emailu, rádi pomůžeme.</p>
                </div>
            </div>
        </div>
    </div>
</template>