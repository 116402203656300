<script setup>
let props = defineProps(["onSubmit"])
</script>
<template>
    <form id="form" action="/api/register_user" class="container" @submit="props.onSubmit">
        <div class="mb-3">
            <label for="first_name" class="form-label">Jméno</label>
            <input type="text" class="form-control" id="first_name" name="first_name" />
        </div>
        <div class="mb-3">
            <label for="last_name" class="form-label">Přijmení</label>
            <input type="text" class="form-control" id="last_name" name="last_name" />
        </div>
        <div class="mb-3">
            <label for="email" class="form-label">Přihlašovací email:</label>
            <input name="email" class="form-control" type="email" />
        </div>
        <div class="mb-3">
            <label for="address" class="form-label">Fakturační Adresa</label>
            <input type="text" class="form-control" id="address" name="address" />
        </div>
        <div class="mb-3">
            <label for="phone" class="form-label">Telefon</label>
            <input type="text" class="form-control" id="phone" name="phone" />
        </div>
        <div class="mb-3">
            <label for="password1" class="form-label">Heslo:</label>
            <input type="password" class="form-control" name="password1"/>
        </div>
        <div class="mb-3">
            <label for="password2" class="form-label">Znovu heslo:</label>
            <input type="password" class="form-control" name="password2"/>
        </div>
        <button type="submit" class="btn btn-primary">Registrovat</button>
    </form>
</template>